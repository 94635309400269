// TractIQ Colors (tractiq)
$tractiq: rgb(18, 87, 221);
$tractiq-50: rgba(18, 87, 221, 0.07);
$tractiq-100: rgba(18, 87, 221, 0.12);
$tractiq-200: rgba(18, 87, 221, 0.2);
$tractiq-300: rgba(18, 87, 221, 0.32);
$tractiq-400: rgba(18, 87, 221, 0.46);
$tractiq-500: rgba(18, 87, 221, 0.56);
$tractiq-600: rgba(18, 87, 221, 0.72);
$tractiq-700: rgba(18, 87, 221, 0.84);
$tractiq-800: rgba(18, 87, 221, 1);
$tractiq-900: rgba(6, 65, 178, 1);

// Black Colors (black)
$black: rgb(0, 0, 0);
$black-50: rgba(0, 0, 0, 0.02);
$black-100: rgba(0, 0, 0, 0.04);
$black-200: rgba(0, 0, 0, 0.08);
$black-300: rgba(0, 0, 0, 0.13);
$black-400: rgba(0, 0, 0, 0.26);
$black-500: rgba(0, 0, 0, 0.38);
$black-600: rgba(0, 0, 0, 0.54);
$black-700: rgba(0, 0, 0, 0.62);
$black-800: rgba(0, 0, 0, 0.74);
$black-900: rgba(0, 0, 0, 0.87);
$black: rgba(0, 0, 0, 1);

// White colors (white)
$white: rgb(255, 255, 255);
$white-50: rgba(255, 255, 255, 0.06);
$white-100: rgba(255, 255, 255, 0.1);
$white-200: rgba(255, 255, 255, 0.14);
$white-300: rgba(255, 255, 255, 0.2);
$white-400: rgba(255, 255, 255, 0.3);
$white-500: rgba(255, 255, 255, 0.38);
$white-600: rgba(255, 255, 255, 0.48);
$white-700: rgba(255, 255, 255, 0.6);
$white-800: rgba(255, 255, 255, 0.72);
$white-900: rgba(255, 255, 255, 0.84);
$white: rgba(255, 255, 255, 1);

// Other colors
$backgroundWhite: rgb(255, 255, 255);
$backgroundSmoke: rgb(245, 245, 245);
$backgroundOuter: rgb(46, 60, 67);
$backgroundLightBlue: rgb(232, 239, 251);
$backgroundLightGray: rgb(218, 213, 213);
$backgroundLightGray2: rgb(249, 249, 249);
$backgroundGray: rgb(235, 235, 235);

$box-shadow-surface-3: rgba(0, 0, 0, 0.2);

$border-color-tractiq: rgba(179, 201, 244, 1);
$hover-color: #f5f5f5;

$ring1: #ED6A5E;
$ring2: #F6BD4F;
$ring3: #29CBC5;

// these colors can be used in bootstrap class names
// e.g. className='bg-tractiq' to set the background
$theme-colors: (
    'tractiq': $tractiq,
    'black': $black,
    'white': $white,
    'gray': rgba($black, 0.13),
    'backgroundWhite': $backgroundWhite,
    'backgroundSmoke': $backgroundSmoke,
    'backgroundOuter': $backgroundOuter,
    'backgroundLightBlue': $backgroundLightBlue,
    'backroundLightGray' : $backgroundLightGray,
    'backgroundLightGray2' : $backgroundLightGray2,
    'ring1': $ring1,
    'ring2': $ring2,
    'ring3': $ring3,
    'backgroundGray': $backgroundGray,
);

$form-select-bg: $tractiq-100;

$opacities: (
    0.02,
    0.04,
    0.06,
    0.07,
    0.08,
    0.1,
    0.12,
    0.13,
    0.14,
    0.2,
    0.26,
    0.3,
    0.32,
    0.38,
    0.46,
    0.48,
    0.54,
    0.56,
    0.6,
    0.62,
    0.72,
    0.74,
    0.84,
    0.87,
    1.00
);

$opacitiesNames: (
    '002',
    '004',
    '006',
    '007',
    '008',
    '010',
    '012',
    '013',
    '014',
    '020',
    '026',
    '030',
    '032',
    '038',
    '046',
    '048',
    '054',
    '056',
    '060',
    '062',
    '072',
    '074',
    '084',
    '087',
    '100'
);

@for $i from 1 through length($opacities) {
    $val: nth($opacities, $i);
    $class: nth($opacitiesNames, $i);
    .opacity-#{$class} {
        opacity: #{$val};
    }
}
