.customize-popup {
    &.card-container {
        width: 100%;
        max-width: 440px;
        height: auto;
        background-color: $white;
        z-index: 1030;
        box-shadow: 0 1px 16px 0 $box-shadow-surface-3;
        position: absolute;
        left: 20px;
        padding: 0 0 16px 0;
    }
    &.header {
        display: flex;
        align-items: center;
        padding: 16px;
        gap: 16px;
    }
    &.title {
        font-size: 19px;
        font-weight: 600;
        color: $black-900;
    }
    &.subheader {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px 24px;
        &.title {
            padding: 6px 0;
            font-size: 17px;
            font-weight: 600;
            color: $black-900;
        }
        &.start {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    &.horizontal-divider {
        padding: 12px 0;
        border-bottom: 1px solid $black-200;
        &.short {
            margin: 0 24px;
        }
    }
    &.data-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 12px;
        align-items: center;
        padding: 6px 0;
        &.data-label {
            width: 100%;
            max-width: 120px;
            font-weight: 400;
            font-size: 15px;
            color: $black-800;
        }
        &.double {
            flex-direction: column;
            gap: 12px;
        }
    }
    &.btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        width: 100%;
        padding: 6px 16px;
    }
    &.switcher {
        display: flex;
        max-width: 260px;
        width: 100%;
        justify-content: space-between;
        &.switch-values {
            flex-grow: 1;
            width: 100%;
            justify-content: space-evenly;
        }
        ul {
            display: flex;
            width: 100%;
            padding: 0;
            margin: 0;
            justify-content: space-evenly;
        }
        li {
            flex: 1;
            text-align: center;
        }
    }
    &.compare-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &.compare-header {
            flex-direction: row;
            justify-content: space-between;
            padding: 12px 24px 0 24px;
        }
    }

    &.filter-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &.filter-header {
            flex-direction: row;
            justify-content: space-between;
            padding: 12px 24px 0 24px;
        }
    }
}

.compare-by-popup {
    &.card-container {
        position: absolute;
        top: calc(37%);
        right: 7%;
        width: 330px;
        height: auto;
        background-color: $white;
        border-radius: 4px;
        border: 1px solid $gray-200;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
        z-index: 2;
        display: flex;
        flex-direction: column;

        &.filter-overflow {
            top: calc(85%);
            right: calc(7%);

            &.small-overflow {
                top: calc(40%) !important;
            }
            &.medium-overflow {
                top: calc(75%) !important;
            }
        }
    }

    &.data-container {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        &.data-item {
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            padding: 12px 16px;

            &.active-item {
                &:hover {
                    background-color: $hover-color;
                }
            }

            &.disabled-item {
                padding: 0;
                color: $black-400;
            }

            &.data-title {
                font-size: 15px;
                font-weight: 600;
                color: $black-900;
                padding: 0;
            }

            &.data-subtitle {
                font-size: 12px;
                font-weight: 400;
                color: $black-600;
                padding: 0;
            }
        }
    }
}

.filter-chip-item {
    &.filter-container {
        display: flex;
        flex-direction: row;
        padding: 4px 28px 20px 24px;
        gap: 12px;
    }
    &.chip-header {
        display: flex;
        justify-content: flex-start;
        align-self: center;
        max-width: 120px;
        width: 100%;
        color: $black-800;
        font-size: 15px;
        font-weight: 400;
    }
    &.chip-item {
        max-width: 350px;
        background-color: $tractiq-100;
        border: 1px solid $tractiq-100;
        color: $tractiq-800;
        gap: 6px;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 600;
        justify-content: flex-start;
        padding: 6px 8px 6px 16px;
    }

    &.filter-items-dropdown {
        position: relative;
        display: flex;
        max-width: 218px;
        width: 100%;
        align-items: flex-end;
    }
}

.compare-chip-item {
    &.chip-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        padding: 4px 28px 20px 24px;
        gap: 12px;
    }
    &.chip-item {
        max-width: 350px;
        background-color: $tractiq-100;
        border: 1px solid $tractiq-100;
        color: $tractiq-800;
        gap: 6px;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 600;
        justify-content: flex-start;
        padding: 6px 8px 6px 16px;
    }
}

.compare-multichip-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &.multichip-container {
        flex-direction: column;
        padding: 4px 28px 20px 24px;
        align-items: flex-start;
    }

    &.multichip-header {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding: 6px 0;
        font-size: 15px;
        font-weight: 400;
        color: $black-800;
        &.header-container {
            flex-direction: column;
            align-items: flex-start;
        }
        &.multichip-subheader {
            font-weight: 400;
            font-size: 13px;
            color: $black-600;
        }
    }
}
